/* WINDOWS HACKS */

@media screen and (-ms-high-contrast:active),
(-ms-high-contrast:none) {
    .intro_bg_img, .fullpg_bg_img {
        background-attachment: scroll !important;
    }
}

@supports (-ms-accelerator: true) {
    .intro_bg_img, .fullpg_bg_img {
        background-attachment: scroll !important;
    }
}

@supports (-ms-ime-align: auto) {
    .intro_bg_img, .fullpg_bg_img {
        background-attachment: scroll !important;
    }
}



/* TARGET IOS DEVICES */

@supports (-webkit-overflow-scrolling: touch) {
  /* CSS specific to iOS devices */ 
    
}



/* FOR NON IOS DEVICES */

@supports not (-webkit-overflow-scrolling: touch) {
  /* CSS for other than iOS devices */ 

    
}

.centered {
    text-align: center;
}

.greeny {
    color: #6EEC1F;
}

h1, h2, h3, h4, h5, h6 {
    line-height: 1.2;
    letter-spacing: 3px;
    
    @include breakpoint(medium) {
        letter-spacing: 6px;
    }
    @include breakpoint(large) {
        letter-spacing: 10px;
    }
}

h2 {
    font-size: 2.2rem;
    @include breakpoint(medium) { font-size: 2.4rem; }
    @include breakpoint(large) { font-size: 2.5rem; }
}

h3 {
    font-size: 1.7rem;
    @include breakpoint(medium) { font-size: 1.8rem; }
    @include breakpoint(large) { font-size: 2rem; }
}

.toolbar_logo {
    display: inline-block;
}

.toolbar_logo img {
    width: 99px;
    height: 70px;
    transition: all .3s ease-in-out;
    
    @include breakpoint(large) {
        width: 120px;
        height: 85px;
    }
}

.home .toolbar_logo img {
    
    @include breakpoint(large) {
        width: 150px;
        height: 106px;
    }
}

.website_header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999;
    width: 100%;
    transition: all .3s ease-in-out;
}

.website_header.scrolled .toolbar_logo img {
    width: 78px;
    height: 55px;
    
    @include breakpoint(large) {
        width: 85px;
        height: 60px;
    }
}

.website_header.navactive {
    transform: translateY(-100%);
}

.secondary_toolbar {
    display: none;
    padding: 14px 5%;
    color: #fff;
    background-color: #1A2942;
    overflow: hidden;
    font-size: 1.2rem;
    
    @include breakpoint(large) {
        display: block;
    }
}

.second_left {
    float: left;
}

.second_right {
    float: right;
}

.main_toolbar {
    position: relative;
    padding: 15px 5% 12px;
    background-color: #1A2942;
    box-shadow: 0 2px 8px rgba(0,0,0,.3);
    overflow: hidden;
    transition: all .3s ease-in-out;
    
    @include breakpoint(large) {
        padding: 15px 3% 12px;
        box-shadow: none;
    }
}

.website_header.scrolled .main_toolbar {
    background-color: rgba(26,41,66,.95);
    box-shadow: 0px 2px 10px rgba(0,0,0,.3);
}

.home .main_toolbar {
    
    @include breakpoint(large) {
        background-color: transparent;
    }
}

.tbar_phone,
.tbar_email {
    display: none;
    position: relative;
    color: #fff;
    margin-right: 30px;
    
    @include breakpoint(large) {
        display: inline-block;
    }
}

.tbar_quote {
    position: relative;
    letter-spacing: 1px;
    color: $goldilocks;
    text-transform: uppercase;
    
    &:before {
        content: '';
        display: inline-block;
        position: relative;
        top: 3px;
        width: 25px;
        height: 25px;
        margin-right: 5px;
        background-image: url('/images/icon_quote.svg');
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }
}

.tbar_phone {
    font-weight: 900;

    
    &:before {
        content: '';
        display: inline-block;
        position: relative;
        top: 6px;
        width: 25px;
        height: 25px;
        margin-right: 5px;
        background-image: url('/images/icon_phone.svg');
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }
}

.tbar_email {
    
    
    &:before {
        content: '';
        display: inline-block;
        position: relative;
        top: 6px;
        width: 25px;
        height: 25px;
        margin-right: 8px;
        background-image: url('/images/icon_mail.svg');
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }
}

.tbar_email,
.tbar_quote,
.tbar_phone {
    transition: all .3s ease-in-out;
}

.tbar_email:hover,
.tbar_quote:hover,
.tbar_phone:hover {
    color: #f6f6f6;
    opacity: .3;
}

.click_to_call_numba {
    padding: 15px 5% 0;
    font-weight: 700;
    font-size: 1.4rem;
    letter-spacing: 1px;
    
    a {
        color: $goldilocks;
    }
    
    @include breakpoint(large) {
        font-size: 1.6rem;
    }
}

.main_right {
    display: inline-block;
    font-size: 1.2rem !important;
    position: absolute;
    top: 50%;
    right: 3%;
    transform: translateY(-50%);
//    float: right;
//    margin: 20px auto;
    
}

.tbar_phone,
.helpr_text {
    letter-spacing: 2px;
    
    @include breakpoint(medium) {
        letter-spacing: 3px;
    }
    @include breakpoint(large) {
        letter-spacing: 5px;
    }
}
 
/* NAVIGATION STYLES */


.helpr_text {
    display: none;
    font-weight: 700;
    transition: all .3s ease-in-out;
    margin-right: 15px;
    position: relative;
}

#mobile_nav_trigga {
    display: inline-block;
    z-index: 99;
    text-align: center;
    line-height: 1;
    cursor: pointer;
}

.helpr_text {
    color: #fff;
    font-weight: 900;
    transition: all .4s ease-in-out;
    
    @include breakpoint(large) {
        color: #fff;
    }
}

//.home .helpr_text,
//.website_header.scrolled .helpr_text {
//    color: #333;
//}

.closing_time {
    position: absolute;
    z-index: 9999;
    top: 12px;
    right: 4%;
    width: 40px;
    height: 40px;
}

@media print,
screen and (min-width:40em) {
    .closing_time {
        top: 30px;
    }
}

.mainliner1,
.mainliner2 {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 3px;
    width: 100%;
//    background: rgba(255, 255, 255, .8);
    background-color: $goldilocks;
    transition: all .3s ease-in-out;
}

.mainliner1 {
    -webkit-transform: translateX(-50%) translateY(-50%) rotate(-45deg);
    -ms-transform: translateX(-50%) translateY(-50%) rotate(-45deg);
    transform: translateX(-50%) translateY(-50%) rotate(-45deg);
}

.mainliner2 {
    -webkit-transform: translateX(-50%) translateY(-50%) rotate(45deg);
    -ms-transform: translateX(-50%) translateY(-50%) rotate(45deg);
    transform: translateX(-50%) translateY(-50%) rotate(45deg);
}

.closing_time:hover .mainliner1,
.closing_time:hover .mainliner2 {
    -webkit-transform: translateX(-50%) translateY(-50%) rotate(0);
    -ms-transform: translateX(-50%) translateY(-50%) rotate(0);
    transform: translateX(-50%) translateY(-50%) rotate(0);
}

.mobile_nav_closer {
    opacity: 0;
}

.burger_time {
    width: 35px;
    height: 20px;
    padding: 0;
    top: 2px;
    position: relative;
    display: inline-block;
    cursor: pointer;
}

.bar1,
.bar2,
.bar3 {
    position: absolute;
    display: block;
    width: 100%;
    height: 3px;
    left: 50%;
    background-color: #fff;
    transition: all .3s ease;
    
    @include breakpoint(large) {
        background-color: #fff;
    }
}

//.home .bar1,
//.home .bar2,
//.home .bar3,
//.website_header.scrolled .bar1,
//.website_header.scrolled .bar2,
//.website_header.scrolled .bar3{
//    background-color: #333;
//}

.bar1 {
    top: 0;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
}

.bar2 {
    top: 50%;
    -webkit-transform: translateY(-50%) translateX(-50%);
    -ms-transform: translateY(-50%) translateX(-50%);
    transform: translateY(-50%) translateX(-50%);
}

.bar3 {
    bottom: 0;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
}

#mobile_nav_trigga:hover .bar1,
.burger_time.navactive .bar1 {
    left: 40%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    transition: all .3s ease;
}

#mobile_nav_trigga:hover .bar2,
.burger_time.navactive .bar2 {
    left: 60%;
    -webkit-transform: translateY(-50%) translateX(-50%);
    -ms-transform: translateY(-50%) translateX(-50%);
    transform: translateY(-50%) translateX(-50%);
    transition: all .3s ease;
}

#mobile_nav_trigga:hover .bar3,
.burger_time.navactive .bar3 {
    left: 40%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    transition: all .3s ease;
}

.offcan_overlay {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9990;
    width: 100%;
    height: 100%;
    background-color: #232323;
    opacity: 0;
}

.offcan_overlay.navactive {
    display: block;
}

#offcan_nav_trigger {
    cursor: pointer;
    width: 47px;
    height: 38px;
}

#homepage_link {
    width: 79px;
    height: 38px;
}

body .navactive {
    overflow-x: hidden;
}

html {
    overflow-x: hidden;
}

.offcan_site_wrapper {
    display: inline-block;
    position: absolute;
    width: 100%;
    height: 100%;
    right: 0;
}

.offcan_nav_wrapper,
.offcan_site_wrapper {
    transition: all .4s;
}

.offcan_site_wrapper.navactive {
    overflow: hidden;
}

body.admin-bar .offcan_nav_wrapper {
    top: 32px !important;
}

.offcan_nav_wrapper {
    position: fixed;
    z-index: 999999;
    top: 0;
    right: -500px;
    display: inline-block;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 0 0 80px;
    background: #001B26;
}

.offcan_nav_wrapper.navactive {
    right: 0;
}

.offcan_nav_wrapper .menu .is-active > a {
    background: rgba(0, 0, 0, 0);
    transition: all .3s ease-in-out;
}

.offcan_nav_wrapper .menu .is-active > a:hover {
    background: rgba(0, 0, 0, .3);
}

.offcan_nav_wrapper .closing_time {
    position: fixed;
    top: auto;
    bottom: 20px!important;
    right: 20px!important;
}

.offcan_nav_wrapper ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.offcan_nav_wrapper ul li {
    position: relative;
    overflow: hidden;
    width: 100%;
}

.offcan_nav_wrapper ul li a {
    color: #fff;
    display: block;
    font-family: inherit;
    font-weight: 900;
    font-size: 1rem;
    letter-spacing: 3px;
    text-transform: uppercase;
    line-height: 1;
    padding: 18px 13px 18px 30px;
    transition: all .4s ease-in-out;
}

.offcan_nav_wrapper ul li a:hover {
    background: rgba(0, 0, 0, .6);
    color: $goldilocks;
}

.offcan_nav_wrapper ul.sub-menu {
    position: relative;
    overflow: hidden;
    height: 0;
    background: rgba(0, 0, 0, .4);
}

.offcan_nav_wrapper ul.sub-menu:before {
    content: '';
    position: absolute;
    width: 8px;
    height: 100%;
    top: 0;
    left: 0;
//    background: $redarmy;
}

.offcan_nav_wrapper ul.sub-menu a:hover {
    color: #666;
}

.offcan_nav_wrapper ul.sub-menu li a {
    padding: 18px 13px 18px 40px !important;
    letter-spacing: 1.5px;
    color: #f8f8f8;
    text-shadow: none;
    opacity: .85;
}

.subset_toggle {
    position: absolute;
    color: #000;
    font-family: inherit;
    cursor: pointer;
    text-align: center;
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0);
    transition: all .4s ease;
}

.subset_toggle {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    top: 1px;
    right: 0;
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0);
    background: url(/images/more_icon.png) no-repeat;
    background-size: 25px 25px;
    background-position: center;
    opacity: .8;
}

.subset_toggle.opened {
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    transition: all .4s ease;
}

#offcan_closer {
    cursor: pointer;
    width: 35px;
    height: 35px;
    position: absolute;
    z-index: 99;
    top: 16px;
    right: 15px;
    opacity: 0;
    transition: all .2s ease;
}

#offcan_closer.navactive {
    opacity: 1;
    transition: all .5s ease;
}

.offcan_search {
    padding: 0 5%;
    margin: 10px 0;
    
    .searchbttn {
        width: 100%;
        height: 100%;
        background-color: #395C72;
        background-image: url('/images/icon_search.svg');
        background-size: 20%;
        background-position: center;
        background-repeat: no-repeat;
        outline: none;
        
        @include breakpoint(large) {
            background-size: 25%;
        }
    }
}

@media only screen and (max-width:1024px) {
        
    

}

@media only screen and (min-width:641px) {
    
    .offcan_site_wrapper.navactive { transform: translateX(-500px); }
    .offcan_nav_wrapper ul li:hover a { transition: all .4s ease; }
    .offcan_nav_wrapper ul li:hover:before { left: -43px; transition: .2s ease; transition-delay: .2s; }
    .helpr_text { display: inline-block; }
    .offcan_nav_wrapper { width: 500px; }    
}

@media only screen and (max-width:640px) {
        
    .offcan_nav_wrapper { width: 100%; right: -100%; }
    .offcan_site_wrapper.navactive { right: 0; }
    
}



// HOMEPAGE AND HERO STYLES



.home_hero {
    position: relative;
    background: #181818;
    
    @include breakpoint(xlarge) {
        min-height: 90vh;
    }
}

.hero_messaging {
    position: relative;
    z-index: 25;
    width: 100%;
    padding: 350px 0 60px;
    
    h1 {
        color: #fff;
        text-transform: uppercase;
        font-size: 2rem;
        
        @include breakpoint(medium) { font-size: 3.6vw; }
        @include breakpoint(large) { font-size: 2.8vw; }
        @include breakpoint(xlarge) { font-size: 2.4vw; }
    }
    @include breakpoint(medium) {
        width: 85%;
    }
    @include breakpoint(large) {
        padding: 400px 0 180px;
        width: 64%;
    }
    @include breakpoint(xlarge) {
        padding: 400px 0 200px;
        width: 55%;
    }
}

.top_grad_overlay {
    display: none;
    position: absolute;
    z-index: 6;
    top: 0;
    left: 0;
    width: 100%;
    height: 15%;
    opacity: .7;
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000000+100&0.9+20,0+100 */
    background: -moz-linear-gradient(top,  rgba(0,0,0,0.9) 0%, rgba(0,0,0,0.9) 20%, rgba(0,0,0,0) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  rgba(0,0,0,0.9) 0%,rgba(0,0,0,0.9) 20%,rgba(0,0,0,0) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,  rgba(0,0,0,0.9) 0%,rgba(0,0,0,0.9) 20%,rgba(0,0,0,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e6000000', endColorstr='#00000000',GradientType=0 ); /* IE6-9 */
    
    @include breakpoint(large) {
        display: block;
    }
}

.left_grad_overlay {
    position: absolute;
    z-index: 5;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    opacity: .6;
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000000+100&0.9+20,0+100 */
    background: -moz-linear-gradient(left,  rgba(0,0,0,0.9) 0%, rgba(0,0,0,0.9) 20%, rgba(0,0,0,0) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left,  rgba(0,0,0,0.9) 0%,rgba(0,0,0,0.9) 20%,rgba(0,0,0,0) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right,  rgba(0,0,0,0.9) 0%,rgba(0,0,0,0.9) 20%,rgba(0,0,0,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e6000000', endColorstr='#00000000',GradientType=1 ); /* IE6-9 */
}

.slide_container {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

}

.hero_slide {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    transition: all .4s ease-in-out;
    background-size: cover !important;
    background-position: center !important;
}

.hero_slide.current {
    opacity: 1;
    z-index: 2;
    
//    animation: mayappearlrgr 6s forwards;
}

//@keyframes mayappearlrgr {
//    0% { transform: scale(1); }
//    100% { transform: scale(1.04); }
//}

.home_content_block {
    padding: 80px 0;
    
    h2 {
        position: relative;
        margin-bottom: 30px;
    }
    
    p {
        
        @include breakpoint(medium) { font-size: 1.2rem; }
        @include breakpoint(large) { font-size: 1.3rem; }
    }
    
    @include breakpoint(medium) {
        padding: 100px 0;
    }
    @include breakpoint(large) {
        padding: 120px 0;

        h2:after {
            content: '';
            position: absolute;
            z-index: 10;
            width: 110%;
            height: 2px;
            left: 15%;
            bottom: -15px;
            background-color: #979797;
            
        }
    }
}

.content_support_img {
    position: relative;
    width: 100%;
    height: auto;
    padding-top: 56%;
    margin-top: 20px;
    
    &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 2px;
        right: -20%;
        bottom: -20px;
        background-color: #979797;
    }
    
    @include breakpoint(large) {
        margin-top: 0;
        height: 100%;
        padding-top: 0;
        width: 120%;
    }
    
}

.content_support_img_inner {
    position: absolute;
    overflow: hidden;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.content_support_img_inner:before {
    content: '';
    position: absolute;
    z-index: 1;
    width: 80px;
    height: 100%;
    top: 0;
    left: 0;
    background-image: url('/images/white_angle.png');
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: left center;
}

.content_support_img_inner img {
    position: absolute;
    min-height: 100%;
//    min-width: 100%;
//    max-width: 200%;
    object-fit: cover;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%,-50%,0);
}

//.content_support_img:before {
//    content: '';
//    position: absolute;
//    z-index: 10;
//    left: -3 0px;
//    top: 0;
//    height: 100%;
//    width: 60px;
//    background-color: #fff;
//    transform: skewX(10deg);
//}

.process_block {
    padding: 80px 0 50px;
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#1f1f1f+0,444444+48,444444+48,444444+100 */
    background: #1f1f1f; /* Old browsers */
    background: -moz-linear-gradient(-45deg,  #1f1f1f 0%, #444444 48%, #444444 48%, #444444 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(-45deg,  #1f1f1f 0%,#444444 48%,#444444 48%,#444444 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(135deg,  #1f1f1f 0%,#444444 48%,#444444 48%,#444444 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1f1f1f', endColorstr='#444444',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
    
    transition: all .3s ease-in-out;
    
    h2 {
        text-transform: uppercase;
        margin-bottom: 50px;
    }

    @include breakpoint(medium) {
        padding: 80px 0 60px;
    }
    @include breakpoint(large) {
        padding: 120px 10% 100px;
    }
}

.process_blck_link_rappa {
    display: none;
    
    @include breakpoint(large) {
        display: block;
    }
}

.process_blck_link_row {
    position: relative;
    text-align: center;
}

.process_blck_link_row:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 40%;
    width: 0;
    height: 3px;
    border-top: 5px dotted #f6f6f6;
    transform: translateY(-50%);
    transition: all .3s ease-in-out;
}

.process_blck_link_row.focused:after {
    content: '';
    position: absolute;
    left: 40%;
    width: 60%;
    height: 3px;
    border-top: 5px dotted #f6f6f6;
}

.process_blck_link {
    position: relative;
    cursor: pointer;
    z-index: 10;
//    display: inline-block;
    width: 15%;
    height: 25px;
//    margin-bottom: 0px auto 15px;
    margin-left: 30%;
    margin-bottom: 20px;
    background-color: #acacac;
    transition: all .3s ease-in-out;
    
}

.process_blck_link:hover {
    background-color: #fff;
}

.process_blck_link.focused {
    transform: scale(1.2);
    background-color: #6EEC1F;
}

.process_blck {
    padding: 0 3%;
    
    h3 {
        color: #dedede;
        text-transform: uppercase;
    }
    
    p {
        color: #fff;
        margin-bottom: 3em;
        
        @include breakpoint(medium) {
            font-size: 1.2rem;
        }
        @include breakpoint(large) {
            font-size: 1.4rem;
        }
        @include breakpoint(xlarge) {
            font-size: 1.5rem;
        }
    }
    
    @include breakpoint(large) {
        display: none;
    }
}

.process_blck.focused {
    display: block;
}

img.staffer {
    max-width: 300px;
    width: 100%;
    
    @include breakpoint(medium) {
        max-width: 100%;
    }
}