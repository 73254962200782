// Navigation breakpoints
.mobile-menu,
.site-title-bar {
  @include hide-for(medium);
}
//
.desktop-menu,
.site-navigation .top-bar-left {
  @include show-for(medium);
}

// Site title
.site-desktop-title a {
  font-weight: bold;
}

// WP post navigation
.post-navigation {
  @include clearfix;
}

.nav-previous {
  float:left;
}

.nav-next {
  float:right;
}
