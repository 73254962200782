.sites-button {
  background: $primary-color;
  font-size: 1.25rem;
  font-weight: bold;
  box-shadow: 0 -2px 0 rgba(0,0,0,0.2) inset;
  border-radius: 3px;
  border: none;
}

.wpcf7-submit,
.buttonALT,
.button {
    font-family: 'Josefin Sans';
    font-weight: 700;
    padding: 18px 20px 15px;
    min-width: 200px;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 3px;
    line-height: 1;
    font-size: 1.1rem;
    margin: 20px 0 10px;
    transition: all .3s ease-in-out;
}

.wpcf7-submit,
.button {
    color: #333;
    background-color: #6EEC1F;
    border: 1px solid #f6f6f6;
    
    &:hover {
        color: #6EEC1F;
        background-color: #444;
    }
}

.buttonALT {
    color: #6EEC1F;
    background-color: #333;
    border: 1px solid #f6f6f6;
    
}

[type='text'], [type='password'], [type='date'], [type='datetime'], [type='datetime-local'], [type='month'], [type='week'], [type='email'], [type='number'], [type='search'], [type='tel'], [type='time'], [type='url'], [type='color'], textarea {
    padding: .8rem;
    margin: 0;
    height: auto;
}

.contact_block textarea {
    margin-top: 25px;
    
    @include breakpoint(large) {
        height: 100%;
        margin-top: 0;
    }
}