.featured-hero {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: rem-calc(100);

  @include breakpoint(medium) {
    height: rem-calc(200);
  }

  @include breakpoint(xlarge) {
    height: rem-calc(400);
  }
}

.standard_featimg {
    position: relative;
    height: 375px;
    background-color: pink;
    background-size: cover !important;
    background-position: top center !important;;
    background-repeat: no-repeat !important;
    
    .left_grad_overlay {
        width: 55%;
    }
    
    h1 {
        position: absolute;
        text-transform: uppercase;
        text-shadow: 0 2px 12px rgba(0,0,0,.5);
        z-index: 10;
        left: 5%;
        bottom: 20px;
        color: #fff;
        opacity: .8;
        
        @include breakpoint(large) {
            left: 6%;
        }
    }
    
    
    @include breakpoint(medium) {
        height: 350px;
    }
    
    @include breakpoint(large) {
        height: 525px;
    }
}

@keyframes condenseIN {
    
    0% { letter-spacing: 60px; opacity: 0; }
    100% { letter-spacing: 10px; opacity: .8; }
}


.standard_featimg,
.featimg_sideways {
    overflow: hidden;
}

.standard_featimg h1,
.featimg_sideways h1 {
    
    font-size: 1.7rem;
    
    @include breakpoint(medium) {
        font-size: 2.4rem;
    }
    @include breakpoint(large) {
        font-size: 3rem;
        width: 2000px;
        letter-spacing: 60px;
        opacity: 0;
        animation: condenseIN 1s forwards;
        animation-delay: .4s;
    }
}

.featimg_sideways {
    position: relative;
    height: 260px;
    background-color: #333;
    background-size: cover !important;
    background-position: top center !important;;
    background-repeat: no-repeat !important;
    
    .left_grad_overlay {
        width: 65%;
    }
    
    h1 {
        position: absolute;
        text-transform: uppercase;
        text-shadow: 0 2px 12px rgba(0,0,0,.5);
        z-index: 10;
        left: 5%;
        bottom: 20px;
        color: #fff;
        opacity: .8;
        
        @include breakpoint(large) {
            left: 6%;
            top: 60px;
            bottom: auto;
        }
        @include breakpoint(xlarge) {
            top: 80px;
        }
    }
    
    
    @include breakpoint(medium) {
        height: 350px;
    }
    
    @include breakpoint(large) {
        height: 100%;
    }
}
