.main-container {
    @include xy-grid-container;
}

.main-grid {
    @include xy-grid();
//    margin-top: 1rem;

    @include breakpoint(medium) {
//        margin-top: 3rem;
    }

    &.sidebar-left {

        // Place the sidebar below main content on small screens ...
        @include breakpoint(small) {
            .main-content {
                order: 1;
            }

            .sidebar {
                order: 2;
            }
        }

        // ... and to the left on medium-up screens, when using the sidebar-left template
        @include breakpoint(medium) {
            .main-content {
                order: 2;
            }

            .sidebar {
                order: 1;
            }
        }
    }

    // Full width template
    .main-content-full-width {
        @include xy-cell(12);
    }

    // Default template
    .main-content {
        @include breakpoint(small) {
            @include xy-cell(12);
        }

        @include breakpoint(medium) {
            @include xy-cell(8);
        }
    }

    .sidebar {
        @include breakpoint(small) {
            @include xy-cell(12);
        }

        @include breakpoint(medium) {
            @include xy-cell(4);
            margin-top: 1rem;
        }
    }
}

h4 {
    font-size: 1.3rem;
    
    @include breakpoint(medium) {
        font-size: 1.3rem;
    }
    @include breakpoint(large) {
        font-size: 1.4rem;
    }
    @include breakpoint(xlarge) {
        font-size: 1.5rem;
    }
}

blockquote {
    background-color: #1A2942;
}

blockquote cite {
    color: #dedede !important;
}

blockquote p,
.wp-block-pullquote blockquote p {
    font-size: 1.2rem;
    
    @include breakpoint(medium) {
        font-size: 1.3rem;
    }
    @include breakpoint(large) {
        font-size: 1.5rem;
    }
    @include breakpoint(xlarge) {
        font-size: 1.6rem;
    }
}

.wp-block-pullquote {
    padding: 2em 0;
}

blockquote, blockquote p {
    color: #5EB5E8;
    line-height: 1.4 !important;
    text-transform: uppercase;
    border: none !important;
}

p {
    
    @include breakpoint(large) {
        font-size: 1.1rem;
    }
}

.topnbottom {
    padding: 30px 0 20px;
    
    @include breakpoint(medium) {
        padding: 40px 0 30px;
    }
    @include breakpoint(large) {
        padding: 60px 0 40px;
    }
    @include breakpoint(xlarge) {
        padding: 80px 0 50px;
    }
    
}

.bottomend {
    padding-bottom: 30px;
}

.inner_rappa {
    position: relative;
    z-index: 20;
    padding: 0 5%;
    
    @include breakpoint(medium) {
        padding: 0 3%;
    }
}

.stats_block {
    position: relative;
    color: #fff;
    background-color: #1A2942;
    background-size: cover !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    padding: 40px 0 50px;
    
    h3 {
        text-align: center;
        color: #5EB5E8;
        text-transform: uppercase;
        margin-top: 3rem;
    }
    
    @include breakpoint(large) {
        padding: 80px 0 70px;
    }
}

.stats > div {
    
    @include breakpoint(medium) {
        border-right: 1px solid rgba(255,255,255,.4);
    }
}

.stats > div:nth-last-of-type(1) {
    border: none;
}

.stats_bg {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-size: cover !important;
    background-position: center !important;
    opacity: .05;
}

.numbered {
    color: #6EEC1F;
    line-height: 1;
    font-size: 5rem;
    margin-top: 30px;
    
    @include breakpoint(medium) {
        margin-top: 0;
        line-height: 1.1;
        font-size: 6.4vw;
    }
    @include breakpoint(large) {
        font-size: 5vw;
    }
}

.number_identity {
    text-transform: uppercase;
    
    @include breakpoint(medium) {
        font-size: 1.8vw;
    }
    @include breakpoint(large) {
        font-size: 1.2vw;
    }
}

.services_links {
    position: relative;
    z-index: 25;
    padding: 60px 0 30px;
    background-color: #EAFFC0;
    
    h2 {
        text-transform: uppercase;
        margin-bottom: 50px;
    }
    
    @include breakpoint(large) {
        padding: 80px 0 120px;
        
        &:before,
        &:after {
            content: '';
            position: absolute;
            height: 90%;
            width: 2px;
            background-color: #979797;
        }
        &:before {
            top: -20%;
            left: 3%;
        }
        &:after {
            bottom: -20%;
            right: 3%;
        }
    }
    @include breakpoint(large) {
        padding: 100px 0 120px;
    }
}

.inves_bg,
.colle_bg,
.invoi_bg,
.negot_bg,
.legal_bg {
    background-repeat: no-repeat;
    background-size: 46%;
    background-position: center;
}
.inves_bg { background-image: url('/images/icon_investigation.svg'); }
.colle_bg { background-image: url('/images/icon_collection.svg'); }
.invoi_bg { background-image: url('/images/icon_invoicing.svg'); }
.negot_bg { background-image: url('/images/icon_negotiating.svg'); }
.legal_bg { background-image: url('/images/icon_legal.svg'); }

.service_link_rappa {
    text-align: center;
    padding: 10px 0 0;
    margin: 30px auto 10px;
    
    @include breakpoint(large) {
        padding: 30px 3%;
        margin: 80px 0 150px;
    }
    @include breakpoint(xlarge) {
        padding: 30px 9%;
    }
}

.service_link_rappa > a:nth-child(even) {
    
    @include breakpoint(large) {
        top: 160px;
    }
}
.service_link_rappa > a:nth-child(even) > .service_link_text {
    
    @include breakpoint(large) {
        top: auto !important;
        bottom: -45px !important;
    }
}

.service_link_icon {
    display: inline-block;
    position: relative;
    border-radius: 50%;
    border: 3px solid #676767;
    margin: 30px 4%;
    
    width: 130px;
    padding-top: 130px;
    transition: all .3s ease-in-out;
    
    @include breakpoint(medium) {
        width: 180px;
        padding-top: 180px;
        border: 4px solid #676767;
    }
    
    @include breakpoint(large) {
        border: 5px solid #676767;
        width: 15%;
        padding-top: 14%;
        margin: 0 1%;
        
        &:hover {
            opacity: .5;
            transform: scale(.95);
        }
    }
}

.service_link_text {
    font-weight: 700;
    color: #676767;
    text-transform: uppercase;
    letter-spacing: 1px;
    display: inline-block;
    width: 100%;
    position: absolute;
    top: -45px;
    left: 50%;
    transform: translateX(-50%);
    
    @include breakpoint(large) {
        font-size: 1.3vw;
    }
}

//.service_link_rappa > a:nth-child(even) > .service_link_icon {
//    top: 160px;
//}

//.service_link_icon {
//    display: inline-block;
//    position: relative;
//    border-radius: 50%;
//    border: 6px solid #676767;
//    width: 15%;
//    margin-left: 1%;
//    margin-right: 1%;
//    padding-top: 14%;
//}


.fullspread_content {
    position: relative;
    margin-top: 90px;
}

.fullspread_content .row {
    max-width: 100%;
    margin: 0;
    
    @include breakpoint(large) {
        min-height: 800px;
    }
}

.fullspread_content .columns,
.fullspread_content .column {
    padding: 0;
}

.content_rappa {
    max-width: 1200px;
    padding: 50px 0 60px;
    margin: 0 auto;
}

.sidebyside_content_rappa,
.content_rappa,
.entry-content {
    padding: 50px 5% 60px;
    
    h2, h3, h4, h5 {
        letter-spacing: 1px;
    }
}

.sidebyside_content_rappa {
    padding: 50px 5% 60px;
    
    @include breakpoint(large) {
        padding: 60px 6% 60px;
    }
    @include breakpoint(xlarge) {
        padding: 80px 8% 80px;
    }
}

.search .fullon_rappa,
.error404 .fullon_rappa {
    min-height: 60vh;
    margin-top: 160px;
    margin-bottom: 60px;
    
    @include breakpoint(large) {
        margin-top: 180px;
        margin-bottom: 100px;
    }
}