.footer-container {
    //  @include xy-grid-container;
    //  border-top: 1px solid $medium-gray;
    //  margin-top: rem-calc(60);
    position: relative;
    z-index: 98;
    width: 100%;
    padding: 30px 5% 40px;
    color: #f6f6f6;
    background-color: #1A2942;
    overflow: hidden;
//    margin-top: 100px;
    
//    @include breakpoint(large) {
//        position: absolute;
//        bottom: 0;
//        left: 0;
//        margin-top: 0;
//    }
}

.home .footer-container {
    position: relative;
    margin-top: 0;
}

.footer-grid {
    @include xy-grid;
//    padding: rem-calc(30) 0;

    section {
        @include xy-cell();

        @include breakpoint(large) {
            @include xy-cell(auto);
        }
    }

    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
    }
}

.credits {
    display: inline-block;
    width: 100%;
    color: #f6f6f6;
    
    @include breakpoint(large) {
        
    }
}

.credits a {
    color: #5EB5E8;
    font-weight: bold;
}



.creds_left {

    
    @include breakpoint(large) {
        float: left;
    }
}

.creds_right {
    
    
    @include breakpoint(large) {
        float: right;
    }
}

.madeby {
    display: inline-block;
    position: relative;
    
    @include breakpoint(large) {
        vertical-align: top;
        margin-top: 6px;
    }
}

.madeby,
.madeby img {
    width: 65px;
    height: 19px;
    transition: all .3s ease-in-out;
}

.madeby:hover {
    opacity: .5;
}

.social_rappa {
    position: relative;
    margin: 15px 0;
    overflow: hidden;
    
    @include breakpoint(large) {
        display: inline-block;
        top: 2px;
        margin: 0 15px 0 0;
    }
}

.social_icon {
    display: inline-block;
    width: 30px;
    height: 30px;
    margin: 0 10px 0 0;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    transition: all .3s ease;
}

.facebook { background-image: url('/images/icon_facebook.svg'); }
.twitter { background-image: url('/images/icon_twitter.svg'); }
.instagram { background-image: url('/images/icon_instagram.svg'); }
.youtube { background-image: url('/images/icon_youtube.svg'); }
.linkedin { background-image: url('/images/icon_linkedin.svg'); }

.social_icon:hover {
    opacity: .4;
}

.contact_block {
    
    a {
        color: #6EEC1F;
        font-weight: bold;
    }
    
    color: #fff;
    background: #1F5F85;
    padding: 60px 0 40px;
    
    @include breakpoint(large) {
        padding: 100px 0;
    }
}

.foota_form_header {
    text-transform: uppercase;
    line-height: 1.2;
    margin-bottom: 2em;
    font-size: 1.3rem;
    
    h2 {
        color: #fff;
        text-shadow: -2px -2px #1A2942;
    }
    
    @include breakpoint(medium) { font-size: 1.4rem; line-height: 1.6; }
    @include breakpoint(large) { font-size: 1.5rem; }
}